import Alpine from 'alpinejs'

export default (function() {
  const slidersMap = new WeakMap()

  Alpine.data('slider', function(conf = {}) {
    let Swiper, Thumbs, Navigation, Pagination
    const meetingDeps = () => [Swiper, Thumbs, Navigation, Pagination].filter(item => item).length === 4

    return {
      currentSlide: 0,
      async init() {
        let slider = this.$root.querySelector('.swiper') || this.$root
        const thumbs = this.$root.querySelector('[data-slider-thumbs]')
        const on = {
          slideChange: (slider) => {
            this.currentSlide = slider.activeIndex
            if (slider.thumbs) slider.thumbs?.swiper?.slideTo(this.currentSlide)
          }
        }
        const load = async () => {
          slidersMap.set(slider, new Promise(async resolve => {
            const instance = new Swiper(slider, {
              on,
              modules: [Thumbs, Navigation, Pagination],
              uniqueNavElements: true,
              slideToClickedSlide: true,
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'opacity-50',
              },
              ...(thumbs ? {
                thumbs: {
                  swiper: await slidersMap.get(thumbs),
                }
              } : {}),
              ...conf,
            })
            slider.__slider = instance
            resolve(instance)
          }))
        }

        if (!meetingDeps()) {
          ;[{ Swiper, Thumbs, Navigation, Pagination }] = await Promise.all([
            import('swiper').then(m => ({ Swiper: m.default, Thumbs: m.Thumbs, Navigation: m.Navigation, Pagination: m.Pagination })),
            import('swiper/css')
          ])
        }

        if (thumbs) Alpine.nextTick(load)
        else load()
      },
    }
  })
})()