import Alpine from 'alpinejs'

export default (function() {
  Alpine.data('tabs', function({ open = 0 } = { open: 0 }) {
    return {
      open,
      heights: [],
      toggle(index) { this.open = this.open !== index ? index : null },
      getHeight(el, index) {
        this.heights[index] = el.scrollHeight

        // listen to size changes
        try {
          new ResizeObserver(() => {
            const newHeight = el.scrollHeight
            if (this.heights[index] !== newHeight) this.heights[index] = newHeight
          }).observe(el)
        } catch (error) {
          // no resize observer possible
        }
      },
      getStyle(index) { return { 'max-height': `${this.open === index ? (this.heights[index] ? this.heights[index] : 0) : 0}px` } },
      getRotatingIndicatorStyle45(index) { return { 'rotate-45': this.open === index } },
      getRotatingIndicatorStyle90(index) { return { 'rotate-90': this.open === index } },
    }
  })
})()