import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'

import 'modules/measure'
import 'modules/modal'
import 'modules/slider'
import 'modules/accordion'
import 'modules/tabs'
import 'modules/form'

Alpine.plugin(intersect)
Alpine.store('app', {})

Alpine.directive('lined', el => {
  const renderNode = document.createElement('span')
  const children = el.childNodes
  if (!children.length) return

  el.classList.add('break-all')
  ;[...children].forEach(child => {
    if (child.nodeType === 3 && child.textContent.length) {
      renderNode.innerHTML = child.textContent
        .trim()
        .split(' ')
        .map(word => `<span class="inline-block bg-white py-1 px-em"> <span class="relative whitespace-nowrap">${word}</span> </span>`)
        .join('')
      child.replaceWith(...renderNode.childNodes)
    }
  })
})

Alpine.data('footer', () => ({
  shown: false,
  toggleContentTransform() {
    const toggle = this.shown // need to be defined outside of a condition
    const content = document.getElementById('content')
    if (!content) return
    if (content.clientHeight > window.innerHeight) {
      ;['shadow-2xl', 'scale-90', 'lg:scale-95'].forEach(className => content.classList.toggle(className, toggle))
    }
  }
}))


window.helpers = {
  toggleOverflow(flag = false) {
    document.body.style.overflow = flag ? null : 'hidden'
  },
}

window.Alpine = Alpine
Alpine.start()
