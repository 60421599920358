import Alpine from 'alpinejs'

export default (function() {
  Alpine.data('modal', function(id) {
    /** @type {(import('micromodal').default|null)} */
    let modal = null
    return {
      id,
      showing: false,
      async load() {
        modal = await import('micromodal').then(m => m.default)
      },
      show() {
        modal.show(this.id, {
          onShow: () => { this.showing = true },
          onClose: () => { this.showing = false },
          disableScroll: true,
        })
      }
    }
  })
})()